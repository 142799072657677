<template>
  <a-modal
    class="evaluate-modal"
    v-model="isEvaluate"
    cancelText="取消"
    okText="提交评价"
    title="用工评价"
    width="1000px"
    @cancel="cancel"
    :confirmLoading="confirmLoading"
    :footer="disabled ? undefined : null"
  >
    <a-row v-if="!disabled">
      <a-col :span="12">
        <div class="evaluateModal_title">项目部：{{ evaluationDetail.projectDepartment }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">项目名称：{{ evaluationDetail.projectName }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">工种/专业：{{ evaluationDetail.majorName }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">项目起止时间：{{ `${formatTime(evaluationDetail.projectStartTime)} - ${formatTime(evaluationDetail.projectEndTime)}` }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">职工姓名：{{ evaluationDetail.realName }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">性别：{{ evaluationDetail.sex === 0 ? '男' : (evaluationDetail.sex === 1 ? '女' : '其他') }}</div>
      </a-col>
      <a-col :span="12">
        <div class="evaluateModal_title">联系电话：{{ evaluationDetail.phone }}</div>
      </a-col>
    </a-row>
    <div class="evaluate_title">对本次用工的总体评价</div>
    <a-row style="text-align: center;">
      <a-col :span="24">
        <a-rate
          v-model="form.score"
          :tooltips="desc"
          :disabled="!disabled"
        />
        <span class="ant-rate-text">{{ desc[form.score - 1] }}</span>
      </a-col>
    </a-row>
    <a-divider />
    <div class="evaluate_title">对本次用工的标签评价</div>
    <a-row :gutter="6">
      <div
        style="margin-bottom: 20px"
        v-for="(item, index) in tagOptions"
        :key="index"
      >
        <a-row>
          <a-col :span="9">
            <span style="font-size: 16px">{{ item.title }}</span>
          </a-col>
          <a-col :span="15">
            <a-row style="margin-bottom: 10px">
              <a-col
                :span="8"
                v-for="(data, dataIndex) in item.detail"
                :key="dataIndex"
              >
                <div
                  class="tag"
                  :class="{ active_tag: data.value === form[`span${index + 1}`] }"
                  @click="disabled && handleChangeTagStaus(index, data.value)"
                >
                  {{ data.value }}
                </div>
              </a-col>
            </a-row>
            <span style="margin-right: 10px">{{ formatTitleLabel(index, 'number') }}</span>
            <span>{{ formatTitleLabel(index, 'numberLabel') }}</span>
          </a-col>
        </a-row>
      </div>
      <!-- <a-col :span="4" v-for="item in typeList" :key="item.id">
          <div
            :class="{
              evaluate_tag: true,
              actived: typeSelect.some(key => key === item.id)
            }"
            @click="handleTypeClick(item.id)"
          >{{ item.label }}</div>
      </a-col>-->
    </a-row>
    <a-divider />
    <div class="evaluate_title">对本次用工的备注</div>
    <a-textarea
      v-model="form.content"
      placeholder="备注信息"
      allow-clear
      :disabled="!disabled"
    />
    <template slot="footer">
      <div>
        <a-button
          type="white"
          @click="cancel"
        >取消</a-button>
        <a-button
          type="primary"
          @click="handleOk"
        >确定</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getDictListByNames } from '@/api/employmentSupport/dict'
import {
  enterpriseEmployeeEvaluationController
} from '@/api/recruitUse'
const basicForm = {
  score: 5,
  content: '',
  span1: '好',
  span2: '好',
  span3: '好'
}

export default {
  name: 'Evaluate',
  data() {
    return {
      evaluationDetail: {},
      disabled: false,
      isEvaluate: false,
      confirmLoading: false,
      form: { ...basicForm },
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意'],
      tagOptions: [],
      tagSelect: ['好', '好', '好'],
      extraObj: {}
    }
  },
  created() {
    getDictListByNames(['employer_evaluation_skill', 'employer_evaluation_safety', 'employer_evaluation_work']).then(
      (res) => {
        this.tagOptions = res.data.map((item) => ({
          ...item,
          title: item.description.split('-')[1],
          detail: item.detail
            .sort((a, b) => b.dictSort - a.dictSort)
            .map((i) => ({
              ...i,
              number: i.label.split('-')[0],
              numberLabel: i.label.split('-')[1]
            }))
        }))
      }
    )
  },
  methods: {
    init(extraObj) {
      this.extraObj = extraObj
      this.$set(this, 'disabled', extraObj.disabled)
      this.isEvaluate = true
    },
    lookInit(val) {
      this.$set(this, 'form', val.row)
      this.form.score = Number(val.row.score)
      this.$set(this, 'evaluationDetail', val.row)
      this.$set(this, 'disabled', val.disabled)
      this.isEvaluate = true
    },
    handleOk() {
      this.confirmLoading = true
      enterpriseEmployeeEvaluationController({
        ...this.extraObj,
        ...this.form,
        flag: 2
      })
        .then(() => {
          this.$message.success('评价成功')
          this.$parent.init()
          this.cancel()
        })
        .finally(() => {
          this.cancel()
          this.confirmLoading = false
        })
    },
    cancel() {
      this.form = {
        score: 5,
        content: '',
        span1: '好',
        span2: '好',
        span3: '好'
      }
      this.isEvaluate = false
    },
    formatTitleLabel(index, key) {
      const data = this.tagOptions[index].detail
      const value = this.form[`span${index + 1}`]
      return data.find((item) => item.value === value)[key]
    },
    handleChangeTagStaus(index, value) {
      this.$set(this.form, `span${index + 1}`, value)
      // this.tagOptions[index].isSelect = !this.tagOptions[index].isSelect
    }
  }
}
</script>

<style lang="less">
.evaluate-modal {
  .tag {
    box-sizing: border-box;
    display: inline-block;
    height: 28px;
    width: 80px;
    background-color: rgba(206, 211, 217, 0.3);
    text-align: center;
    border-radius: 17px;
    line-height: 28px;
    color: #4d72f7;
    font-size: 12px;
    cursor: pointer;
  }

  .active_tag {
    color: #ffffff;
    background-color: #4d72f7;
  }
}
</style>
